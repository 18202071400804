import React, { createContext, useState } from 'react';
import { IShift } from '../interfaces/IShift.ts';

type SelectedVehicleContextProviderProps = {
  children: React.ReactNode;
};

interface ContextType {
  selectedVehicle: ISelectedVehicle | undefined;
  setSelectedVehicle: (selectedVehicle: ISelectedVehicle | undefined) => void;
}

export interface ISelectedVehicle {
  latitude: number;
  longitude: number;
  vehicleId: number;
  vehicleDisplayIdentifier: string;
  shift: IShift;
}

const defaultValue = {
  selectedVehicle: undefined,
  setSelectedVehicle: () => {},
};
export const SelectedVehicleContext = createContext<ContextType>(defaultValue);

export const SelectedVehicleContextProvider = ({ children }: SelectedVehicleContextProviderProps) => {
  const [selectedVehicle, setSelectedVehicle] = useState(undefined);

  return (
    <SelectedVehicleContext.Provider
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={{ selectedVehicle, setSelectedVehicle }}>
      {children}
    </SelectedVehicleContext.Provider>
  );
};
