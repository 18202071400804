import { Button, Card, Col, Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { getProducts } from '../../queries/getProducts.ts';
import { Key, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

export interface Territory {
  id: number;
  product: number;
  dispatch_identifier: string;
  provider: string;
  slug: string;
}

export interface Product {
  id: number;
  name: string;
  url: string;
  territories: Territory[];
  has_connections_active: boolean;
  backend_version: string;
}

export function Product() {
  const { t } = useTranslation();
  const {
    data: products,
    error: productsError,
    isLoading: productsLoading,
  } = useQuery({
    queryKey: ['products'],
    queryFn: getProducts,
    retry: false,
    enabled: !!sessionStorage.getItem('token'),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const error = productsError as AxiosError;
    if (
      error !== null &&
      error !== undefined &&
      error.response?.status &&
      error.response?.status >= 500
    ) {
      navigate('/server-error/');
    }
  }, [productsError]);

  const onClick = (territory: Territory) => {
    navigate(`dashboard/${territory.slug}/`, {
      state: territory,
    });
  };

  return (
    <div>
      <h1 className={'text-center product-page-title mt-5'}>{t('product.title')}</h1>
      <div className={'row d-flex mb-5 me-5 ms-5 pb-5'}>
        {productsLoading ? (
          <div className={'text-center mt-5 pt-5'}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {products?.data.map((product: Product, i: Key) =>
              product.territories.map((territory: Territory, j: Key) => (
                <Col className={'col-md-6 col-xl-4'} key={String(i) + String(j)}>
                  <Card
                    className={'text-center pb-3 border-0 rounded-3 basic-shadow gx-5 mt-5 ms-3 me-3'}>
                    <div className={'text-end me-3 mt-3'}>
                      <p
                        className={
                          product.has_connections_active
                            ? 'badge rounded-pill bg-success'
                            : 'badge rounded-pill bg-danger'
                        }>
                        {product.has_connections_active ? 'Enabled' : 'Disabled'}
                      </p>
                    </div>
                    <h1 className={'product-name-text mt-3'}>{product.name}</h1>
                    <h1 className={'territory-name-text mt-1'}>{territory.dispatch_identifier}</h1>
                    <Button
                      onClick={() => onClick(territory)}
                      disabled={!product.has_connections_active}
                      className={'create-mission-btn m-auto ps-5 pe-5 mt-4'}>
                      Dashboard
                    </Button>
                    <hr className={'w-50 m-auto mt-3'} />
                    <a
                      className={'login-forgotten-password mt-4'}
                      target="_blank"
                      href={product.url}
                      rel="noreferrer">
                      {product.url}
                    </a>
                  </Card>
                </Col>
              )),
            )}
          </>
        )}
      </div>
    </div>
  );
}
