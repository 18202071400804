import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Key, useContext, useEffect, useState } from 'react';
import { GetMapCenterCoordinated } from '../../../utils.ts';
import RecenterMap from './RecenterMap.tsx';
import L, { LatLngTuple } from 'leaflet';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { IVehicle } from '../../../interfaces/Vehicle.ts';
import { SelectedNodeContext } from '../../../contexts/SelectedNodeContext.tsx';

interface Position {
  lat: number;
  lon: number;
}
export interface Node {
  id: number;
  name: string;
  position: Position;
  padam_id: number;
  padam_name: string;
}
interface Props {
  geography: Node[];
  territorySlug: string;
  vehicles: IVehicle[] | undefined;
}

export function Map(props: Props) {
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const { selectedNode } = useContext(SelectedNodeContext);
  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>([]);

  const [center, setCenter] = useState<LatLngTuple>();
  const [zoom, setZoom] = useState(17);

  const vehicle_icon = new L.Icon({
    iconUrl: '/theme/media/leaflet_padam_icons/bus.svg',
    iconSize: [60, 60],
    popupAnchor: [1, -34],
    iconAnchor: [35, 60],
    offset: [0, 35],
  });

  const vehicle_selected_icon = new L.Icon({
    iconUrl: '/theme/media/leaflet_padam_icons/bus-selected.svg',
    iconSize: [80, 80],
    popupAnchor: [1, -34],
    iconAnchor: [45, 80],
    offset: [0, 35],
  });

  const node_selected_icon = new L.Icon({
    iconUrl: '/theme/media/leaflet_padam_icons/selected-pin.svg',
    iconSize: [65, 89],
    iconAnchor: [31, 67],
    popupAnchor: [1, -34],
  });

  const node_icon = new L.Icon({
    iconUrl: '/theme/media/leaflet_padam_icons/blue-pin.svg',
    iconSize: [30, 47],
    iconAnchor: [14, 46],
    popupAnchor: [1, -34],
  });

  useEffect(() => {
    setVehicles(props.vehicles);
    if (props.geography !== undefined && typeof props.geography !== 'string') {
      if (selectedVehicle?.vehicleId !== undefined) {
        setCenter([selectedVehicle?.latitude || 0, selectedVehicle?.longitude || 0]);
        setZoom(17);
      } else {
        setCenter(GetMapCenterCoordinated(props.geography));
        setZoom(15);
      }
    }
  }, [
    selectedVehicle,
    props.geography,
    props.vehicles,
    props.territorySlug,
    selectedNode,
    selectedNode?.name,
  ]);

  if (center !== undefined) {
    return (
      <div className={'shadow-sm map-container'}>
        <MapContainer center={center} scrollWheelZoom={true}>
          <RecenterMap lat={center[0]} lng={center[1]} zoom={zoom} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.geography ? (
            props.geography.map((node: Node, i: Key) => (
              <Marker
                icon={
                  node.padam_name === selectedNode?.name &&
                  selectedNode !== null &&
                  node.padam_name !== null
                    ? node_selected_icon
                    : node_icon
                }
                position={[node.position.lat, node.position.lon]}
                key={i}>
                <Popup className={'m-0 p-0'}>
                  <div className={'row'}>
                    <div className={'col'}>
                      <p className={'vehicle-identifier m-0 p-0'}>{node.padam_name}</p>
                      <p>
                        Node index: {node.padam_id} <br />
                        Latitude: {node.position.lat} <br />
                        Longitude: {node.position.lon}
                      </p>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))
          ) : (
            <></>
          )}
          {vehicles ? (
            vehicles.map((vehicle: IVehicle, i: Key) => (
              <Marker
                icon={vehicle.id === selectedVehicle?.vehicleId ? vehicle_selected_icon : vehicle_icon}
                position={[vehicle.latitude || 0, vehicle.longitude || 0]}
                key={i}>
                <Popup>Vehicle : {vehicle.display_identifier}</Popup>
              </Marker>
            ))
          ) : (
            <></>
          )}
        </MapContainer>
      </div>
    );
  }
}
